export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * hide or show the date of a post
   */
  showDatesOnPosts: boolean;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Conjurer Solutions',
  description: 'Adding the magic to make your business flow',
  coverImage: 'img/BLACK_I_desktop-1.jpg',
  logo: 'img/conjurer-icon.png',
  lang: 'en',
  siteUrl: 'https://competent-pare-0a1c36.netlify.com',
  facebook: 'https://www.facebook.com/conjurersolutions',
  twitter: 'https://twitter.com/conjurer',
  showDatesOnPosts: false,
  showSubscribe: true,
  mailchimpAction: 'https://conjurersolutions.us14.list-manage.com/subscribe/post?u=a5d9c76d4391b6566612d8688&amp;id=d1b6b2c881',
  mailchimpName: 'b_a5d9c76d4391b6566612d8688_d1b6b2c881',
  mailchimpEmailFieldName: 'MERGE0',
  googleSiteVerification: 'GoogleCode',
  footer: 'adds the magic to make your business flow',
};

export default config;
